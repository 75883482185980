import React from 'react'
import logo from "../Fichiers/Logo.png";
import logoNoel from "../Fichiers/LogoNoel.png";

interface Props { }

function Load(props: Props) {
    const { } = props
      const [mois, setMois] = React.useState((((new Date(Date.now()))).getMonth()+1))
    
      setTimeout(()=>{
        setMois((((new Date(Date.now()))).getMonth()+1))
      }, 1000)
      // {(mois == 12 || mois == 1)? logoNoel : logo}

    return (
        <div className='loading'>
            <img width={250} className='img-loading'src={(mois == 12 || mois == 1)? logoNoel : logo} alt="MUPECI"  />
        </div>
    )
}

export default Load
